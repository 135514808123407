export const FooterComponent: React.FC = () => {
    return (
        <footer className="footer jb-bg-gradient overflow-hidden pb-4" id="Footer">
        <div className="container jb-footer-bottom">
            <div className="row">
                <div className="col-md-4">
                    <div className="jb-foot-logo">
                        <img src="assets/logo.png" className="mt-2 logo-square-border" alt="logo-png" title="insync logo" />
                    </div>
                    <p className="mt-4 text-left jb-ftr-about">Social Media Links :</p>
                    <div className="mt-4">
                        <ul className="jb-footer-social list-inline mt-4">
                            <li className="list-inline-item"><a href="https://facebook.com/100086010199833/"
                                    rel="noopener noreferrer" className="jb-social-icon jb-color" target="_blank"><i
                                        className="icofont icofont-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="https://www.instagram.com/_insynctherapies/"
                                    rel="noopener noreferrer" className="jb-social-icon jb-color" target="_blank"><i
                                        className="icofont icofont-instagram"></i></a></li>
                            <li className="list-inline-item"><a
                                    href="https://www.linkedin.com/in/insync-therapies-718a73252/"
                                    rel="noopener noreferrer" className="jb-social-icon jb-color" target="_blank"><i
                                        className="icofont icofont-linkedin"></i></a></li>
                            <li className="list-inline-item"><a href="https://wa.me/+918767619524?text=Hi%20Payal,"
                                    rel="noopener noreferrer" className="jb-social-icon jb-color" target="_blank"><i
                                        className="icofont icofont-whatsapp"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-2 col-sm-4">
                    <h5 className="jb-footer-title">Quick Links</h5>
                    <ul className="jb-footer-menu list-unstyled mb-0 mt-4">
                        <li className="nav-item"><a href="#Home">Home</a></li>
                        <li className="nav-item"><a href="#My-story">My Story</a></li>
                        <li className="nav-item"><a href="#AboutUs">About InSync</a></li>
                        <li className="nav-item"><a href="#Services">Services</a></li>
                        <li className="nav-item"><a href="#Testimonials">Testimonials</a></li>
                    </ul>
                </div>
                <div className="col-md-2 col-sm-4">
                    <h5 className="jb-footer-title">Useful Links</h5>
                    <ul className="jb-footer-menu list-unstyled mb-0 mt-4">
                        <li className="nav-item"><a href="#FAQ">FAQ's</a></li>
                        <li className="nav-item"><a href="#Appointment">Appointment</a></li>
                    </ul>
                </div>
                <div className="col-md-4 col-sm-4">
                    <h5 className="jb-quick-contact">Quick Contact<i className="icofont icofont-circled-down"></i></h5>
                    <ul className="jb-footer-menu jb-footer-menu
                    jb-footer-menu-static list-unstyled mb-0 mt-4 jb-contact-menu-list">

                        <li><i className="icofont icofont-envelope"></i><a
                                href="mailto:payal@insynctherapies.in">payal@insynctherapies.in</a></li>
                        <li><i className="icofont icofont-phone"></i><a href="tel:+91 020 46502623"><span
                                    className="align-middle">+91 020 46502623</span></a></li>
                        <li><i className="icofont icofont-phone"></i><a href="tel:+91 98200 89571"><span
                                    className="align-middle">+91 87676 19524</span></a></li>
                    </ul>
                </div>
                <div className="col-md-12">
                    <div className="jb-footer-desc mt-4 pt-4">
                        <p className="mb-0 text-center">
                            2024/2025 &copy; <span className="text-white font-weight-bold">InSync
                                Therapies</span>
                            <br/>
                            Made with <i className="icofont icofont-heart-alt footer-heart"></i>
                            <a href="https://www.justbetechsoft.in/" target="_blank" rel="noopener noreferrer"
                                className="text-white font-weight-bold">Just Be
                                Techsoft Pvt. Ltd.
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}