import ScrollAnimation from 'react-animate-on-scroll';

export const WorkshopComponent: React.FC = () => {
    return (
        <section className="section jb-aboutus" id="Workshops">
            <div className="container jb-about-scale" >
            <div className="row">
                <div className="col-md-12">
                    <div className="jb-section-title text-center mb-4">
                        <h2>Workshops / Seminars</h2>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <ScrollAnimation  animateIn="slideInLeftJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                    <div className="jb-aboutus-icon-box d-flex align-items-start">
                        <div className="icon jb-b-color jb-about-icon jb-color"><i
                                className="icofont icofont-location-arrow"></i></div>
                        <div className="ml-4 jb-about-space">
                            <h4 className="title">Dignity Foundation Chai Masti Centre – Kalyani Nagar & Wanowrie</h4>
                            <p className="description text-justify">
                                This was an interactive session with the Senior Citizens on the
                                Subconscious Mind. Introducing them to the power of the subconscious
                                mind and affirmations. Also teaching them how to communicate with
                                the subconscious mind and how to use affirmations and visualisation for
                                achieving desired goals and dreams. It was mainly focused on stabilizing
                                their physical and mental issues.
                            </p>
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation  animateIn="slideInRightJb" animateOnce duration={0.5} className="col-md-6 about-card-text p-5">
                    <div className="row story-img-gallery jb-b-color">
                        <div className="col gallery gallery-1">
                            <div className="gallery-img">
                                <img className="img-fluid" src="assets/workshop-dignity-foundation.png" alt="Dignity-Foundation-Chai-Masti-Centre"
                                    title="Dignity Foundation Chai Masti Centre – Kalyani Nagar & Wanowrie Insync Therapies" />
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="row mb-5 life-vision-board">
                <ScrollAnimation  animateIn="slideInLeftJb" animateOnce duration={0.5} className="col-md-6 about-card-text p-5">
                    <div className="row story-img-gallery jb-b-color">
                        <div className="col gallery gallery-1">
                            <div className="gallery-img">
                                <img className="img-fluid" src="assets/workshop-life-vision-board.jpeg" alt="Life-Vision-Board-in-collaboration-with-Thrivesome"
                                    title="Life Vision Board in collaboration with Thrivesome Insync Therapies" />
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation  animateIn="slideInRightJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                    <div className="jb-aboutus-icon-box d-flex align-items-start">
                        <div className="icon jb-b-color jb-about-icon jb-color"><i
                                className="icofont icofont-location-arrow"></i></div>
                        <div className="ml-4 jb-about-space">
                            <h4 className="title">Life Vision Board in collaboration with Thrivesome</h4>
                            <p className="description text-justify">
                                The Transformative Life Vision Board Workshop was conducted to
                                unlock the power of Subconscious Mind to manifest your goals and dreams. A workshop where participants crafted their Dream Board, Life
                                Vision Board with captivating collage of images, affirmations, and
                                aspirations that fuel them with inspiration and motivation. The
                                participants discovered how incredible is the impact of attaching
                                emotions to their dreams and a chance to explore innovative paths
                                toward achieving goals and embrace the magic of visualization.
                            </p>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="row mb-5">
                <ScrollAnimation  animateIn="slideInLeftJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                    <div className="jb-aboutus-icon-box d-flex align-items-start">
                        <div className="icon jb-b-color jb-about-icon jb-color">
                            <i className="icofont icofont-location-arrow"></i>
                        </div>
                        <div className="ml-4 jb-about-space">
                            <h4 className="title">Power of the Subconscious Mind - Ficci Flo Pune Chapter</h4>
                            <p className="description">
                                The subconscious mind is a reservoir of immense potential, often
                                untapped and waiting to be discovered. In this transformative workshop,
                                we dove into the incredible realm of the subconscious mind,
                                understanding its intricacies, how it affects our lives, exploring its
                                language and learning how to harness its power to manifest dreams.
                            </p>
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation  animateIn="slideInRightJb" animateOnce duration={0.5} className="col-md-6 about-card-text p-5">
                    <div className="row story-img-gallery jb-b-color">
                        <div className="col gallery gallery-1">
                            <div className="gallery-img">
                                <img className="img-fluid" src="assets/workshop-posm.jpeg" alt="Power-of-the-Subconscious-Mind-Ficci-Flo-Pune-Chapter"
                                    title="Power of the Subconscious Mind - Ficci Flo Pune Chapter Insync Therapies" />
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    </section>
    )
}