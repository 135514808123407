export const ServicesComponent: React.FC = () => {
    return (
        <section className="section" id="Services">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="jb-section-title text-center mb-4">
                        <h2>Our Services</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="jb-dept-box jb-b-color">
                        <div>
                            <img src="assets/individual-services.jpg" alt="individual-services-jpg" className="w-100"
                                title=" Individual Services" />
                        </div>
                        <div className="jb-dept-details">
                            <div className="jb-round-style"></div>
                            <i className="icon icofont jb-color">&#xecfe;</i>
                            <h4>Individual Session </h4>
                            <p className="jb-details">
                            <ul className="jb-services-list text-left">
                                <li>
                                    Discovery Call
                                </li>
                                <br/>
                                <li>
                                    RTT Session
                                </li>
                                <br/>
                                <li>
                                    Bespoke Recording
                                </li>
                                <br/>
                                <li>
                                    Follow-up via Call, Message or Email
                                </li>
                                <br/>
                                <li>
                                    2nd Session – Parts of RTT or
                                    Alternative Healing Modalities
                                </li>
                                <br/>
                                <li>
                                    Follow Up via Call, Message or Email
                                </li>
                            </ul>
                            </p>
                            <li className="nav-item contact-btn">
                                <a href="#Appointment" className="jb-thm-btn jb-bg-color"><i
                                        className="icofont icofont-envelope"></i>
                                    Contact</a>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="jb-dept-box jb-b-color">
                        <div>
                            <img src="assets/group-services.jpg" alt="group-services-jpg" className="w-100"
                                title="Group-Services" />
                        </div>
                        <div className="jb-dept-details">
                            <div className="jb-round-style"></div>
                            <i className="icon icofont jb-color">&#xed09;</i>
                            <h4>Group Program </h4>
                            <p className="jb-details">
                            <ul className="jb-services-list text-left">
                                <li>
                                    Confidence
                                </li>
                                <br/>
                                <li>
                                    Abundance
                                </li>
                                <br/>
                                <li>
                                    Anxiety
                                </li>
                                <br/>
                                <li>
                                    Communication Skills
                                </li>
                                <br/>
                                <li>
                                    Quit Smoking
                                </li>
                                <br/>
                                <li>
                                    Self Esteem and other topics
                                </li>
                            </ul>
                            </p>
                            <li className="nav-item contact-btn">
                                <a href="#Appointment" className="jb-thm-btn jb-bg-color"><i
                                        className="icofont icofont-envelope"></i>
                                    Contact</a>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="jb-dept-box jb-b-color">
                        <div>
                            <img src="assets/workshop-seminar.jpg" alt="workshop-seminar-jpg" className="w-100"
                                title="Workshop Seminar" />
                        </div>
                        <div className="jb-dept-details">
                            <div className="jb-round-style"></div>
                            <i className="icon icofont jb-color">&#xed0a;</i>
                            <h4>Workshop/Seminar</h4>
                            <p className="jb-details">
                            <ul className="jb-services-list text-left">
                                <li>
                                    Schools – Confidence, sports teams, exams passing, nerves, anxiety, public speaking
                                </li>
                                <br/>
                                <li>
                                    Corporates – sales team, confidence, performance, stress or work pressures, public
                                    speaking,
                                </li>
                                <br/>
                                <li>
                                    Any Clubs, Organisations, Groups, Associations, Clinics. (De-Addiction Centres, Gym
                                    or Weight Clinics, Fertility Clinics)
                                </li>
                            </ul>
                            </p>
                            <li className="nav-item contact-btn">
                                <a href="#Workshops" className="jb-thm-btn jb-bg-color">
                                    View Recent Workshops / Seminars</a>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>      

    );
};
