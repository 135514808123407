export const NavComponent: React.FC = () => {
    return (
        <nav className="navbar navbar-expand-lg fixed-top jb-custom-nav sticky">
            <div className="container">
                <a
                    className="navbar-brand jb-brand-logo mr-4"
                    href="index.html"
                >
                    <img
                        src="assets/logo.png"
                        className="img-fluid jb-logo-light"
                        alt="logo-png"
                        title="insync logo"
                    />
                    <img
                        src="assets/logo.png"
                        className="img-fluid jb-logo-dark"
                        alt="logo-png"
                        title="insync logo"
                    />
                </a>
                <div
                    className="navbar-collapse collapse justify-content-center"
                    id="navbarCollapse"
                >
                    <ul className="navbar-nav navbar-center" id="mySidenav">
                        <li className="nav-item active">
                            <a href="#Home" className="nav-link jb-color">
                                Home
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#My-story" className="nav-link jb-color">
                                My Story
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#AboutUs" className="nav-link jb-color">
                                About InSync
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#Services" className="nav-link jb-color">
                                Services
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="#Testimonials"
                                className="nav-link jb-color"
                            >
                                Testimonials
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#FAQ" className="nav-link jb-color">
                                FAQ's
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="#Appointment"
                                className="nav-link jb-color"
                            >
                                Reach Out To Us
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="jb-contact_btn">
                    <li className="list-inline-item">
                        <a
                            href="https://wa.me/+918767619524?text=Hi%20Payal,"
                            rel="noopener noreferrer"
                            className="jb-social-icon jb-color"
                            target="_blank"
                        >
                            <i className="icofont icofont-whatsapp"></i>
                        </a>
                    </li>
                    <button
                        className="navbar-toggler jb-color ml-2 p-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="icofont icofont-navigation-menu"></i>
                    </button>
                </div>
            </div>
        </nav>
    );
};
