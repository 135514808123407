import { useState } from "react";

export const AppointmentComponent: React.FC = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        moreInfo: "More Details",
        phone: "",
        email: "",
        message: "",
    });


    const [errors, setErrors] = useState({
        fullName: "",
        dropDown: "",
        phone: "",
        email: "",
        message: "",
    });

    const resetForm = () => {
        setFormData({
            fullName: "",
            moreInfo: "More Details",
            phone: "",
            email: "",
            message: "",
        })
    }
    const [emailResponse, setemailResponse] = useState(" ");

    const validateFullName = (fullNameValue: string) => {
        if (!fullNameValue.trim()) {
            setErrors((prev) => ({ ...prev, fullName: "Full Name is required" }));
            return false;
        } else if (fullNameValue.trim().length < 3) {
            setErrors((prev) => ({ ...prev, fullName: "Please write full name" }));
            return false;
        } else {
            setErrors((prev) => ({ ...prev, fullName: "" }));
            return true;
        }
    };

    const validatePhone = (phoneValue: string) => {
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneValue || !phoneValue.match(phoneRegex)) {
            setErrors((prev) => ({
                ...prev,
                phone: "Phone number must be 10 digits",
            }));
            return false;
        } else {
            setErrors((prev) => ({ ...prev, phone: "" }));
            return true;
        }
    };

    const validateEmail = (emailValue: string) => {
        if (!emailValue) {
            setErrors((prev) => ({ ...prev, email: "Email is required" }));
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailValue.match(emailRegex)) {
            setErrors((prev) => ({ ...prev, email: "Email is invalid" }));
            return false;
        }
        setErrors((prev) => ({ ...prev, email: "" }));
        return true;
    };

    const validateForm = () => {
        const isFullNameValid = validateFullName(formData.fullName);
        const isPhoneValid = validatePhone(formData.phone);
        const isEmailValid = validateEmail(formData.email);
        return isFullNameValid && isPhoneValid && isEmailValid;
    };

    const sendMail = async () => {
        try {
            const payload = { formData: { ...formData } };

            const BASE_URL = process.env.REACT_APP_JB_MAILER_BASE_URL;

            const response = await fetch(BASE_URL + "/api/inquiry/1", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                throw new Error("Failed to send email");
            }
            setemailResponse("Thank you for reaching out and we’ll revert at the earliest.");
            setTimeout(() => setemailResponse(" "), 5000);
            console.log("Email sent successfully");

        } catch (error) {
            setemailResponse("Something went wrong. Please try after sometime.");
            setTimeout(() => setemailResponse(" "), 5000);
            console.error("Error sending email:", error);
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (validateForm()) {
            sendMail();
            resetForm();
        } else {
            console.log("Form contains errors.");
        }
    };

    return (
        <section className="p-0">
            <div className="jb-appointment jb-bg-color">
                <div className="row no-gutters">
                    <div className="col-md-2" id="Appointment"></div>
                    <div className="col-md-8">
                        <div className="jb-appointment-form">
                            <h2 className="title">Reach Out To Us</h2>
                            <form
                                action="https://app.us8.list-manage.com/subscribe/post?u=11c7f8e5dccd407bde129be7f&amp;id=f4e7b055e8&amp;f_id=00f666e0f0"
                                method="post"
                                id="mc-embedded-subscribe-form"
                                name="mc-embedded-subscribe-form"
                                className="validate"
                                target="_blank"
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fullName"
                                                name="FNAME"
                                                placeholder="Full Name *"
                                                value={formData.fullName}
                                                onChange={(e) => {
                                                    const fullNameValue = e.target.value;
                                                    setFormData({ ...formData, fullName: fullNameValue })
                                                    validateFullName(fullNameValue);
                                                }}
                                                onBlur={(e) => validateFullName(e.target.value)}
                                            />
                                            <span id="fullName-error">{errors.fullName}</span>
                                            {formData.fullName && !errors.fullName && (
                                                <i className="icofont icofont-check-circled"></i>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <select
                                                className="form-control"
                                                id="dropDown"
                                                name="MMERGE2"
                                                value={formData.moreInfo}
                                                onChange={(e) =>
                                                    setFormData({ ...formData, moreInfo: e.target.value })
                                                }
                                            >
                                                <option value="More Details">More Details</option>
                                                <option value="Individual Session">
                                                    Individual Session
                                                </option>
                                                <option value="Group Session">Group Session</option>
                                                <option value="Workshop &amp; Seminar">
                                                    Workshop &amp; Seminar
                                                </option>
                                                <option value="Collaboration">Collaboration</option>
                                            </select>
                                            <span id="dropDown-error">{errors.dropDown}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="id_phone_number"
                                                name="PHONE"
                                                placeholder="Phone Number"
                                                value={formData.phone}
                                                onChange={(e) => {
                                                    const phoneValue = e.target.value;
                                                    setFormData({ ...formData, phone: phoneValue })
                                                    validatePhone(phoneValue);
                                                }}
                                                onBlur={(e) => validatePhone(e.target.value)}
                                            />
                                            <span id="phone-error">{errors.phone}</span>
                                            {formData.phone && !errors.phone && (
                                                <i className="icofont icofont-check-circled"></i>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="id_email"
                                                name="EMAIL"
                                                placeholder="Email *"
                                                value={formData.email}
                                                onChange={(e) => {
                                                    const emailValue = e.target.value;
                                                    setFormData({ ...formData, email: emailValue });
                                                    validateEmail(emailValue);
                                                }}
                                                onBlur={(e) => validateEmail(e.target.value)}
                                            />
                                            <span id="email-error">{errors.email}</span>
                                            {formData.email && !errors.email && (
                                                <i className="icofont icofont-check-circled"></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        rows={4}
                                        placeholder="Message"
                                        id="message"
                                        name="MMERGE3"
                                        value={formData.message}
                                        onChange={(e) =>
                                            setFormData({ ...formData, message: e.target.value })
                                        }
                                    ></textarea>
                                    <span id="message-error">{errors.message}</span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <span className="alertMsg">
                                            <i className="icofont icofont-info-circle"></i> * fields
                                            are mandatory.
                                        </span>
                                    </div>
                                </div>
                                <div id="mce-responses" className="clear foot">
                                    <div className="response" id="mce-error-response"></div>
                                    <div className="response" id="mce-success-response"></div>
                                </div>
                                <div className="jb-appointment-element" aria-hidden="true">
                                    <input
                                        type="text"
                                        name="b_11c7f8e5dccd407bde129be7f_f4e7b055e8"
                                        tabIndex={-1}
                                        value=""
                                    />
                                </div>
                                {emailResponse && (
                                    <div
                                        className={`emailResponse ${emailResponse.includes("Thank you for reaching out and we’ll revert at the earliest.")
                                            ? "success-response"
                                            : "error-response"
                                            }`}
                                    >
                                        <p>{emailResponse}</p>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-md-4 jb-btn-main">
                                        <button
                                            type="submit"
                                            value="Subscribe"
                                            name="subscribe"
                                            id="mc-embedded-subscribe"
                                            className="btn jb-book-now-btn"
                                        >
                                            Reach Now
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </section>
    );
};
