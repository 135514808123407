export const CoverComponent: React.FC = () => {
    return (
        <section
            className="jb-bg-gradient overflow-hidden jb-home-section"
            id="Home"
        >
            <div className="jb-waves-bg jb-home-bg">
                <div className="container">
                    <div className="owl-carousel owl-theme jb-home-slider">
                        <div className="item">
                            <div className="row align-items-center">
                                <div className="col-md-6 p-5">
                                    <div className="jb-content-fadeInUp">
                                        <h1 className="heading">
                                            Welcome to InSync Therapies
                                        </h1>
                                        <h2
                                            className="text-white jb-sub-title"
                                            style={{
                                                padding: "15px 0 15px 0",
                                            }}
                                        >
                                            In Sync: Mind, Body & Soul
                                        </h2>
                                        <p
                                            className="text-white jb-sub-title"
                                            style={{
                                                fontSize: "22px",
                                                fontWeight: 700,
                                                padding: "15px 0 15px 0",
                                            }}
                                        >
                                            <span className="jb-para-name">
                                                Payal Bhhaskaran
                                            </span>
                                            <br />
                                            Clinical Hypnotherapist, Rapid
                                            Transformational Therapist, Access
                                            Bars Practitioner, Essential Oils
                                            Specialist, Counsellor.
                                        </p>
                                        <div className="jb-learn-more">
                                            <a
                                                href="#AboutUs"
                                                className="btn jb-btn-white jb-btn-rounded text-white"
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-8">
                                            <div className="jb-img-fadeInRight img-margin">
                                                <img
                                                    src="assets/logo-without-text.png"
                                                    className="img-fluid "
                                                    alt="logo-without-text-png"
                                                    title="insync logo"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="jb-hero-waves">
                    <svg
                        width="100%"
                        height="100%"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="wave"
                    >
                        <defs></defs>
                        <path id="jb-wave1" d="" />
                    </svg>
                    <svg
                        width="100%"
                        height="100%"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="wave"
                    >
                        <defs></defs>
                        <path id="jb-wave2" d="" />
                    </svg>
                </div>
            </div>
        </section>
    );
};
