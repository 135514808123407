import ScrollAnimation from 'react-animate-on-scroll';

export const AboutUsComponent: React.FC = () => {
    return (
        <section id="AboutUs" className="jb-aboutus section">
            <div className="container jb-about-scale">
                <div className="row">
                    <div className="row">
                        <div className="col">
                            <div className="jb-section-title mb-4 about-heading">
                                <div className="jb-about-logo text-center after-479">
                                    <img src="assets/logo-without-text.png" className="img-fluid" alt="logo-without-text-png"
                                        title="insync logo" />
                                </div>
                                <h4 className="jb-sub-title">About InSync</h4>
                                <p className="text-justify">
                                    Here at InSync Therapies I provide sessions that vary from RTT to counselling
                                    to Access Bars and many others. These sessions deliver extraordinary,
                                    permanent change from physical, emotional, and psychological pain by
                                    reframing our core beliefs, values, habits and emotions that are deep in the
                                    subconscious mind. Within the sessions are: Rapid Transformational Therapy
                                    (RTT), Access Bars and Body Processes, Essential Oils, Healy Frequencies, Bach
                                    Flower Remedies and others. It works rapidly and removes the need for
                                    continuous therapy sessions. A single session can achieve powerful results for
                                    a range of issues and the techniques can be applied to both children and
                                    adults.
                                </p>
                                <p className="text-justify">
                                    More complicated issues and conditions may require up to three sessions or
                                    more depending upon the issue.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ScrollAnimation animateIn="slideInLeftJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                            <div className="jb-aboutus-icon-box d-flex align-items-start">
                                <div className="icon jb-b-color jb-about-icon jb-color"><i
                                    className="icofont icofont-location-arrow"></i></div>
                                <div className="ml-4 jb-about-space">
                                    <h4 className="title">Rapid Transformtional Therapy (RTT) - </h4>
                                    <p className="description text-justify">
                                        <ul>
                                            <li className="text-justify">
                                                RTT is a hybrid therapy developed by Marisa Peer.
                                            </li>
                                            <li className="text-justify">
                                                RTT combines best of Hypnotherapy, Cognitive Behavioural Therapy,
                                                Neuro Linguistic Programming and Neuroscience.
                                                With its unique methods RTT delivers permanent, lasting change.
                                                Its personalized approach works with clients to help them reframe any
                                                negative beliefs, values, habits and emotions – many of which they have carried
                                                with them since childhood.
                                                Often, these have been so deeply buried in their subconscious that they are
                                                unaware of the past issues affecting them.
                                            </li>
                                            <li className="text-justify">
                                                By giving the client, the potential to alter the way they think and
                                                put an end to negative self-talk, they can approach life in a positive way and
                                                move forward
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="slideInRightJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                            <div className="jb-aboutus-icon-box d-flex align-items-start">
                                <div className="icon jb-b-color jb-about-icon jb-color">
                                    <i className="icofont icofont-location-arrow"></i></div>
                                <div className="ml-4 jb-about-space">
                                    <h4 className="title">Counselling - </h4>
                                    <p className="description text-justify">
                                        Counselling is a professional service that provides a safe, confidential,
                                        and non-judgmental space for individuals to explore their thoughts, feelings, and
                                        behaviours.
                                        Through active listening, empathy, and guidance, a counsellor helps clients
                                        understand and navigate their emotional and psychological challenges.
                                        The goal of counselling is to empower individuals to make positive changes, develop
                                        coping strategies, and enhance their overall well-being.
                                        Whether dealing with stress, anxiety, depression, relationship issues, or life
                                        transitions, counselling offers support and tools for personal growth and healing.
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="row">
                        <ScrollAnimation animateIn="slideInLeftJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                            <div className="jb-aboutus-icon-box d-flex align-items-start">
                                <div className="icon jb-b-color jb-about-icon jb-color"><i
                                    className="icofont icofont-location-arrow"></i></div>
                                <div className="ml-4 jb-about-space">
                                    <h4 className="title">Cognitive Behaviour Therapy (CBT) - </h4>
                                    <p className="description text-justify">
                                        CBT is a talking therapy that can help you manage your problems
                                        by changing the way you think and behave. It's most commonly used to treat anxiety
                                        and depression, but can be useful for other mental and physical health problems.
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="slideInRightJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                            <div className="jb-aboutus-icon-box d-flex align-items-start">
                                <div className="icon jb-b-color jb-about-icon jb-color">
                                    <i className="icofont icofont-location-arrow"></i>
                                </div>
                                <div className="ml-4 jb-about-space">
                                    <h4 className="title">Emotional Freedom Technique (EFT) - </h4>
                                    <p className="description text-justify">
                                        EFT is an alternative treatment for physical pain and emotional
                                        distress. It's also referred to as tapping or psychological acupressure. People who
                                        use this technique believe tapping the body can create a balance in your energy
                                        system and treat pain.
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="row">
                        <ScrollAnimation animateIn="slideInLeftJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                            <div className="jb-aboutus-icon-box d-flex align-items-start">
                                <div className="icon jb-b-color jb-about-icon jb-color">
                                    <i className="icofont icofont-location-arrow"></i>
                                </div>
                                <div className="ml-4 jb-about-space">
                                    <h4 className="title">Access Consciousness - </h4>
                                    <p className="description">
                                        <ul>
                                            <li className="text-justify">
                                                Access Bars – Access Bars is a gentle treatment using light touch on the 32
                                                points on your head that effortlessly release the thoughts, ideas, beliefs and
                                                emotions that are blocking you and your growth. Created by Dr. Dain Heer and
                                                Gary Douglas, Access Bars are used as a potent and pragmatic tool by
                                                families, wellness practitioners, schools, businesses, mental health
                                                professionals, athletes, prisons, veterans, artists, and many more.
                                            </li>
                                            <li className="text-justify">
                                                Body Processes - An Access Consciousness Body Process is a hands-on method that
                                                uses different hand placements on various positions on the body while asking
                                                specific energies to run at each spot. These processes facilitate the body back
                                                into its original functions, which assist with the repairing and longevity of
                                                the body.
                                            </li>
                                            <li className="text-justify">
                                                Clearing Statements - Much of what we would like to change is not cognitive or
                                                logical; it is created and held energetically. The potency of the Access
                                                Consciousness Clearing Statement is its capacity to clear the hidden stuff that
                                                you aren’t even aware of that is keeping you stuck! It clears the energy
                                                underneath the words. It is a simple tool that thousands of people around the
                                                world use every day to change almost anything that is keeping them stuck,
                                                limited or tied up in knots.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="slideInRightJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                            <div className="jb-aboutus-icon-box d-flex align-items-start">
                                <div className="icon jb-b-color jb-about-icon jb-color"><i
                                    className="icofont icofont-location-arrow"></i></div>
                                <div className="ml-4 jb-about-space ">
                                    <h4 className="title">Bach Flower Remedy - </h4>
                                    <p className="description text-justify">
                                        Bach flower remedies are an alternative or complementary treatment that is used for
                                        emotional problems and pain. They’re made out of watered-down extracts from the
                                        flowers of wild plants. Edward Bach, a medical doctor and homeopath, created these
                                        remedies in the early 1900s. Homeopathy is the belief that the body can cure itself.
                                        It uses small amounts of natural substances like plants and minerals to treat the
                                        body or mind. The idea behind Bach flower remedies is similar to homeopathy. But
                                        they use fewer materials and don't work directly on physical symptoms, but instead
                                        on the emotions. Bach believed that healing negative emotions helps the body heal
                                        itself. His system contains 38 remedies that each address a specific negative
                                        emotion. The emotions are grouped into seven broad psychological causes of illness:
                                        <br />
                                            <ul>
                                                <li>
                                                    Fear
                                                </li>
                                                <li>
                                                    Uncertainty
                                                </li>
                                                <li>
                                                    Lack of interest in present circumstances
                                                </li>
                                                <li>
                                                    Loneliness
                                                </li>
                                                <li>
                                                    Oversensitivity to influences and ideas
                                                </li>
                                                <li>
                                                    Sadness or despair
                                                </li>
                                                <li>
                                                    Cares for others at the expense of self
                                                </li>
                                            </ul>
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="row">
                        <ScrollAnimation animateIn="slideInLeftJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                            <div className="jb-aboutus-icon-box d-flex align-items-start">
                                <div className="icon jb-b-color jb-about-icon jb-color"><i
                                    className="icofont icofont-location-arrow"></i></div>
                                <div className="ml-4 jb-about-space">
                                    <h4 className="title">Essential Oils – </h4>
                                    <p className="description">
                                        <ul>
                                            <li className="text-justify">
                                                Essential oils are a form of alternative medicine that employs plant extracts to
                                                support health and well-being. Essential oils are concentrated plant extracts
                                                that retain the natural smell and flavour, or “essence,” of their source.
                                            </li>
                                            <li className="text-justify">
                                                Essential Oils can be used aromatically, topically and by ingestion.
                                            </li>
                                            <li className="text-justify">
                                                Essential Oils work wonders in dealing with stress, anxiety, sleep issues, and
                                                pain. It helps reduce inflammation, cure headaches, digestion issues, and colds.
                                                It’s widely used in skincare, hair care and for its antibiotic and antimicrobial
                                                properties.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="slideInRightJb" animateOnce duration={0.5} className="col-md-6 about-card-text">
                            <div className="jb-aboutus-icon-box d-flex align-items-start">
                                <div className="icon jb-b-color jb-about-icon jb-color"><i
                                    className="icofont icofont-location-arrow"></i></div>
                                <div className="ml-4 jb-about-space ">
                                    <h4 className="title">Healy - </h4>
                                    <p className="description text-justify">
                                        Healy is a portable device with frequency technology to support balancing and
                                        harmonising the physical, mental and
                                        emotional body through low frequency electrical stimulation. The Healy device based
                                        on the discoveries of quantum
                                        physics is capable of measuring and analysing the vibrations of our body that is, it
                                        can identify which frequencies are
                                        causing the imbalance in our body. Healy device has a quantum of bioenergetic field
                                        that can be manipulated to promote
                                        health. The Healy aims to interact with this bioenergetic field, it employs
                                        particular micro frequency currents to
                                        target specific areas of the body, mind and influence the emotional state.
                                        Healy device has specific programmes to address a wide range of health problems from
                                        stress and anxiety to chronic pain
                                        and depression, from skin and beauty to internal organs, sleep issues, aura scan,
                                        from bioenergetic fields to all the
                                        meridians.
                                    </p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </section>
    );
};
