export const IssuesAddressedListComponent: React.FC = () => {
    return (
        <>
            <section className="section before-479">
                <div className="container">
                    <div className="row ser-sub-main-h">
                        <div className="col text-center">
                            <h1>Issues Addressed In Therapy</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col offset-1">
                                <div className="row text-center">
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Addictions</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Procrastination</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Weight Issues</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Anxiety</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Eating Disorders</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Stress</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Interview Skills</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Fears &amp; Phobias</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Nerves</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Hair Growth</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Depression</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Public Speaking</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Panic Attacks</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Concentration</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Skin Conditions</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Guilt</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Exams</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Pain Control</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Relationships</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Memory</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Money</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Sexual Issues</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Confidence</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Insomnia</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Infertility</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Anger</li>
                                        </ul>
                                    </div>
                                    <div className="col-4 text-left list-main">
                                        <ul className="list-style">
                                            <li> Grief/Trauma</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section after-479">
                <div className="container">
                    <div className="row ser-sub-main-h">
                        <div className="col text-center">
                            <h1>Issues Addressed In Therapy</h1>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Addictions</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Procrastination</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Weight Issues</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Anxiety</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Eating Disorders</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Stress</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Interview Skills</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Fears &amp; Phobias</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Nerves</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Hair Growth</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Depression</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Public Speaking</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Panic Attacks</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Concentration</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Skin Conditions</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Guilt</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Exams</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Pain Control</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Relationships</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Memory</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Money</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Sexual Issues</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Confidence</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Insomnia</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Infertility</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Anger</li>
                                </ul>
                            </div>
                            <div className="col-6 text-left list-main">
                                <ul className="list-style">
                                    <li> Grief/Trauma</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
