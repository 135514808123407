import ScrollAnimation from 'react-animate-on-scroll';

export const MyStoryComponent: React.FC = () => {
    return (
        <section id="My-story">
            <div className="container">
                <div className="row story-margin">
                    <div className="col-md-6 mt-4 text-left">
                        <ScrollAnimation animateIn="fadeInDown" animateOnce delay={500}>
                            <h4>Hello! I’m Payal</h4>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="slideInLeftJb" animateOnce duration={1.5} className="text-justify">
                            <p>
                                My life has been full of twists and turns, leaving a lasting ripple effect. At the tender age of
                                two, my left arm was amputated in an accident and I battled all kinds of insecurities,
                                self-doubts & inferiority complexes throughout childhood. Despite these challenges, I didn’t
                                fall behind my peers because of my growing support system. My family and friends didn’t let me
                                waver off in any aspect of life. I grew up to be a confident and an independent woman. I was a
                                dancer & choreographer before entering the field of events as a manager across all genres of
                                events.
                            </p>
                            <p>
                                As I entered married life and embarked upon motherhood I was falsely diagnosed with asthma and
                                provided wrong treatment for over a decade by many renowned doctors. Struggling with a growing
                                ailment, I felt bombarded with domestic issues, and in between all of this my husband’s untimely
                                demise nailed me to the ground. As a single mom I not only raised my ten-year-old son, but also
                                fought grief and loss. My health deteriorated due to the latter and uncovered Autoimmune
                                Disorder, resulting in Rheumatoid Arthritis. I spiralled even deeper with the steroids and
                                oxygen therapy recommended to tend to damaged lungs. The side effects of these conditions, such
                                as weight-gain and sleep apnea (among others), affected me deeply. I faced massive depression,
                                isolation, and frustration. In a nutshell, I completely lost myself.
                            </p>
                            <p>
                                But given my relentless spirit, I rose above the circles of hell by entering and absorbing
                                numerous alternative healing modalities. Working through the checklist of problems, I gained
                                some semblance of “normality”. Tackling every problem one after the other over the years has
                                brought me back to being the woman I once was before the downfall.
                            </p>
                            <p>
                                Having dealt with problems of my own and practicing these methods and many others on myself, I
                                can relate to all kinds of issues faced before and whilst in therapy. Discovering the root cause
                                of an issue and uncovering the limiting beliefs holding a person back is challenging. My
                                objective is to help change the meaning attached to events from your past so you can be
                                liberated and free. Reclaiming your voice, your power, and your self-worth is the concept behind
                                InSync Therapies.
                            </p>
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-6 align-self-center">
                        <ScrollAnimation animateIn="slideInRightJb" animateOnce duration={1.5} className="story-img-gallery-main">
                            <div className="row story-img-gallery jb-b-color">
                                <div className="col gallery gallery-1">
                                    <div className="gallery-img">
                                        <img className="img-fluid" src="assets/payal-image-1.jpg" alt="payal-image-1"
                                            title="payal image" />
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </section>

    );
};

