import React, { useEffect } from "react";
import { CoverComponent } from "./components/cover.component";
import { NavComponent } from "./components/nav.component";
import { MyStoryComponent } from "./components/mystory.component";
import { CertificationComponent } from "./components/certification.component";
import { AboutUsComponent } from "./components/aboutus.component";
import { ServicesComponent } from "./components/services.component";
import { IssuesAddressedListComponent } from "./components/issues-addressed-list.component";
import { CertificationsComponent } from "./components/certifications.component";
import { WorkshopComponent } from "./components/workshop.component";
import { TestimonialsComponent } from "./components/testimonials.component";
import { FAQComponent } from "./components/faq.component";
import { AppointmentComponent } from "./components/appointment.component";
import { FooterComponent } from "./components/footer.component";
import { BackToTopComponent } from "./components/backtotop.component";


function App() {
    useEffect(() => {}, []);

    return (
        <>
            <NavComponent />
            <CoverComponent />
            <MyStoryComponent />
            <CertificationComponent />
            <AboutUsComponent />
            <ServicesComponent />
            <IssuesAddressedListComponent />
            <CertificationsComponent />
            <WorkshopComponent />
            <TestimonialsComponent />
            <FAQComponent />
            <AppointmentComponent />
            <FooterComponent />
            <BackToTopComponent />
        </>
    );
}

export default App;
