export const FAQComponent: React.FC = () => {
    return (
        <section id="FAQ" className="section">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="jb-section-title text-center mb-4">
                        <h2>Frequently Asked Questions</h2>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                    <div id="jb-accordion" className="jb-faq-panel">
                        <div className="jb-question-box shadow-sm active">
                            <div className="jb-faq-header p-0" id="jb-heading-One">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color" data-toggle="collapse"
                                        data-target="#jb-collapse-One" aria-expanded="false"
                                        aria-controls="jb-collapse-One"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> How long does it
                                            take?</span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-One" className="collapse show" aria-labelledby="jb-heading-One"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        Each Rapid Transformational Therapy session lasts between 90 minutes to 2 hours.
                                        For that reason, you are asked to block a full 2 hours in your calendar to make
                                        sure you’re not rushed.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Two">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#collapseTwo" aria-expanded="false"
                                        aria-controls="collapseTwo"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> What does it feel
                                            like?
                                        </span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="jb-heading-Two"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        The experience feels different for different people. Some people feel a floating
                                        sensation, some do not. While some may feel sleepy. Most people feel relaxed and
                                        at ease. What’s most important is to know that how you feel isn’t an indicator
                                        of how effective it is. Regardless of what it feels like - it’s working. I
                                        promise.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Three">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#jb-collapse-Three" aria-expanded="false"
                                        aria-controls="jb-collapse-Three"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> Can I get “stuck”
                                            in
                                            hypnosis?</span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-Three" className="collapse" aria-labelledby="jb-heading-Three"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        No! That’s an old wives’ tale. You have complete control the whole time. You can
                                        talk, move your body, get a tissue, even get up and leave (I’ve had clients who
                                        have stopped midway to use the washroom). For online sessions, if our call gets
                                        disconnected you may drift into sleep if you’re really relaxed, but eventually
                                        you’ll notice that you’re not hearing my voice and open your eyes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Four">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#jb-collapse-Four" aria-expanded="false"
                                        aria-controls="jb-collapse-Four"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> Will I be under
                                            your
                                            power?</span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-Four" className="collapse" aria-labelledby="jb-heading-Four"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        No! You will be in charge. You will not be made to do anything against your
                                        will. You will be alert and aware of everything that is happening throughout the
                                        session.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Five">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#jb-collapse-Five" aria-expanded="false"
                                        aria-controls="jb-collapse-Five"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> What if I can’t be
                                            hypnotised?</span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-Five" className="collapse" aria-labelledby="jb-heading-Five"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        You can. Everyone can. As long as you are willing to enter hypnosis, you will.
                                        It’s an easy, enjoyable and thoroughly relaxing process.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Six">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#jb-collapse-Six" aria-expanded="false"
                                        aria-controls="jb-collapse-Six"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> How does it
                                            work?</span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-Six" className="collapse" aria-labelledby="jb-heading-Six"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        Hypnosis is not magic - it’s science! It works by creating a burst of brain
                                        waves that are the same as when you’re in REM. That’s what allows you to access
                                        the subconscious mind. It’s very easy and anyone can do it, you’ll see it for
                                        yourself when you do it too!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Seven">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#jb-collapse-Seven" aria-expanded="false"
                                        aria-controls="jb-collapse-Seven"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> What if I don’t
                                            “go deep”
                                            enough?</span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-Seven" className="collapse" aria-labelledby="jb-heading-Seven"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        Don’t worry about how deep you go. I want you to forget all about that. It's
                                        really not important. The depth of trance is not linked to results at all. So
                                        don’t get stuck on “Am I deep enough?” Just tell yourself the truth - “This is
                                        working” and it will absolutely work. Just know that it is effective.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Eight">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#jb-collapse-Eight" aria-expanded="false"
                                        aria-controls="jb-collapse-Eight"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> What if I think I
                                            already know the
                                            reasons behind my issue?</span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-Eight" className="collapse" aria-labelledby="jb-heading-Eight"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        What makes RTT amazing is that even if you think you know the reason, you see it
                                        in a COMPLETELY new way. That’s what allows you to change the meaning and
                                        ultimately, change your beliefs. And for many clients they go back to scenes
                                        that are totally different than what they expected. Just relax and trust that
                                        your subconscious mind will show you exactly what you need to see.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Nine">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#jb-collapse-Nine" aria-expanded="false"
                                        aria-controls="jb-collapse-Nine"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> What if I go back
                                            to scenes that are
                                            painful or scary?</span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-Nine" className="collapse" aria-labelledby="jb-heading-Nine"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        If you go back to traumatic scenes from your life, it’s important to remember
                                        that you are not reliving that scene, you are simply reviewing it and that you
                                        are safe. I will support you and create a safe space for you to express your
                                        emotions and heal - you’ll be OK.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Ten">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#jb-collapse-Ten" aria-expanded="false"
                                        aria-controls="jb-collapse-Ten"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> What if I uncover
                                            something I don’t
                                            want to share?</span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-Ten" className="collapse" aria-labelledby="jb-heading-Ten"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        That’s alright. As long as you tell me how that scene is making you feel, I will
                                        still be able to help you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Eleven">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#jb-collapse-Eleven" aria-expanded="false"
                                        aria-controls="jb-collapse-Eleven"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> When will I start
                                            to see changes?
                                        </span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-Eleven" className="collapse" aria-labelledby="jb-heading-Eleven"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p>
                                        In RTT changes can come in three ways -
                                    <ul>
                                        <li className="text-justify">
                                            Immediate: You feel a massive shift right away – immediate changes in your
                                            physiology, thoughts and behaviours right in the session and the following
                                            days.
                                        </li>
                                        <li className="text-justify">
                                            Incremental: You see consistent shifts every day, or over time.
                                        </li>
                                        <li className="text-justify">
                                            Retroactive: You don’t see the shifts right away and then one day you
                                            suddenly look back and see all of the things that are different in your
                                            life.
                                        </li>
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="jb-question-box shadow-sm">
                            <div className="jb-faq-header p-0" id="jb-heading-Twelve">
                                <h4 className="mb-0">
                                    <button className="btn-link jb-color collapsed" data-toggle="collapse"
                                        data-target="#jb-collapse-Twelve" aria-expanded="false"
                                        aria-controls="jb-collapse-Twelve"><span><i
                                                className="icofont icofont-location-arrow faq-arrow"></i> How many sessions
                                            do I need?
                                        </span><span><i
                                                className="icofont icofont-rounded-down caret-icon"></i></span></button>
                                </h4>
                            </div>
                            <div id="jb-collapse-Twelve" className="collapse" aria-labelledby="jb-heading-Twelve"
                                data-parent="#jb-accordion">
                                <div className="jb-faq-body">
                                    <p className="text-justify">
                                        The number of sessions depends on your unique situation. RTT is a very powerful
                                        technique and most issues can be resolved in as little as one to three sessions.
                                        Deeper or more complex issues may require additional sessions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-1"></div>
            </div>
        </div>
    </section>

    )
}