import ScrollAnimation from 'react-animate-on-scroll';

export const CertificationComponent: React.FC = () => {
    return (
        <section className="section" id="certificates">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 cer-container">
                        <ScrollAnimation animateIn="slideInLeftJb" animateOnce duration={0.5} className="col cer-box jb-b-color cer-card">
                            <div className="row cer-card-img">
                                <div className="col text-center p-3">
                                    <img src="assets/IACT-member.png" alt="IACT-member-png" className="img-fluid cer-img"
                                        title="iact logo" />
                                </div>
                            </div>
                            <div className="row cer-card-text">
                                <div className="col pt-4 px-4 text-center">
                                    <h4>IACT Member</h4>
                                </div>
                            </div>
                            <div className="row cer-card-text">
                                <div className="col p-4 text-muted text-center">
                                    <p>IACT is association that provides a forum where members exchange ideas, information
                                        and techniques.</p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-3 cer-container">
                        <ScrollAnimation animateIn="slideInUpJb" animateOnce duration={0.5} className="col cer-box jb-b-color cer-card">
                            <div className="row cer-card-img">
                                <div className="col text-center p-3">
                                    <img src="assets/cognitive-behavioural-therapy.png"
                                        alt="cognitive-behavioural-therapy-png" className="img-fluid cer-img jb-cert-round"
                                        title="cognitive behavioural therapy logo" />
                                </div>
                            </div>
                            <div className="row cer-card-text">
                                <div className="col pt-4 px-4 text-center">
                                    <h4>Certified CBT</h4>
                                </div>
                            </div>
                            <div className="row cer-card-text">
                                <div className="col p-4 text-muted text-center">
                                    <p>Psychology-based teachings and applications that influence how people think, believe
                                        and behave.</p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-3 cer-container">
                        <ScrollAnimation animateIn="slideInUpJb" animateOnce duration={0.5} className="col cer-box jb-b-color cer-card">
                            <div className="row cer-card-img">
                                <div className="col text-center p-3">
                                    <img src="assets/RTT-CL-HYP-Logo.png" alt="RTT-CL-HYP-Logo-png"
                                        className="img-fluid cer-img" title="RTT CL HYP Logo" />
                                </div>
                            </div>
                            <div className="row cer-card-text">
                                <div className="col pt-4 px-4 text-center">
                                    <h4>RTT CL.HYP</h4>
                                </div>
                            </div>
                            <div className="row cer-card-text">
                                <div className="col p-4 text-muted text-center">
                                    <p>RRT is a hybrid therapy combining the principles of Hypnotherapy, CBT, Psychotherapy
                                        and NLP.</p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-md-3 cer-container">
                        <ScrollAnimation animateIn="slideInRightJb" animateOnce duration={0.5} className="col cer-box jb-b-color cer-card">
                            <div className="row cer-card-img">
                                <div className="col text-center p-3">
                                    <img src="assets/eos.png" alt="Essential-Oil-Specialist-png"
                                        className="img-fluid cer-img jb-cert-round" title="Essential Oil Specialist Logo" />
                                </div>
                            </div>
                            <div className="row cer-card-text">
                                <div className="col pt-4 px-4 text-center">
                                    <h4>EOS - doTERRA</h4>
                                </div>
                            </div>
                            <div className="row cer-card-text">
                                <div className="col p-4 text-muted text-center">
                                    <p>Essential Oil Specialist certification makes us more efficient in the use of essential oils & essential oil products.</p>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="nav-item jb-learn-more p-3">
                    <a href="#Certifications" className="btn jb-btn-white jb-btn-rounded text-white jb-cert-button">View Certifications</a>
                </div>
            </div>
        </section>
    );
};

