export const TestimonialsComponent: React.FC = () => {
    return (
        <section className="section jb-bg-gradient" id="Testimonials">
        <div className="container before-479">
            <div className="row">
                <div className="col-md-12">
                    <div className="jb-section-title text-center mb-4">
                        <h2 className="text-white">Testimonials</h2>
                        <p className="text-white jb-section-subtitle mx-auto">
                            InSync Therapies has touched many lives, here’s how…
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                    <div className="owl-carousel owl-theme jb-testimonial-slider">
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-1">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-1">
                                        <p className="text-justify">
                                            I am so thankful to God that I met Payal Bhhaskaran, my therapist and a very
                                            lovely person that she is indeed !! <br />
                                            I was struggling with obesity and severe health issues like thyroid and bad
                                            cholesterol and due to all these things I was suffering from mood swings and
                                            depression. The sessions with her be it hypnotherapy or counselling has
                                            immensely helped me in not only lifting my low spirits but also motivated me
                                            in finding self-love and working on myself constructively. I have found this
                                            new person in me who is happy and proactive and takes all the ups & downs
                                            with a pinch of salt. <br />
                                            I have become much calmer and optimistic after my therapy. I would suggest
                                            anyone who is suffering from depression or any kind of mal-adjustment with
                                            life should definitely go for therapy with Payal!! It will surely help you
                                            guys in handling life as it comes in a sensible and composed way.
                                            Thank you once again for everything.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Apurva Joshi</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-2">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-2">
                                        <p className="text-justify">
                                            I had been suffering from chronic pain from TMJ for years and had seen a lot
                                            of doctors who had put me on a number of medicines which gave me temporary
                                            relief only. I came to know about hypnotherapy and met Payal through one of
                                            the internet platforms. As I was unaware of this branch of healing, she
                                            explained things very calmly and patiently. I became keen on trying out the
                                            therapy and I booked an appointment hoping to have some improvement in the
                                            pain. <br />
                                            She took every detail of my case and aptly told me that I was extremely
                                            stressed and exhausted which was adding to the pain, making it worse.
                                            <br />
                                            I started taking sessions from Payal and the pain started to reduce and I
                                            also started feeling positive about myself. After 6 sessions the pain has
                                            completely disappeared. She also gave me personalised recordings to hear
                                            daily which calmed my mind and filled me with positivity.
                                            <br />
                                            I wonder if I had got this therapy done years earlier, I wouldn’t have
                                            suffered so much. <br />
                                            Regards, <br />
                                            Rishabh
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Rishabh</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-3">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-3">
                                        <p className="text-justify">
                                            I am 24 years old and I have been overweight almost all my life. I just
                                            couldn’t deal with being overweight and the remarks that followed leading to
                                            crash diets followed by binge eating. I completely lacked stability and
                                            balance. Payal as a therapist is truly amazing. I could understand the root
                                            cause of being overweight after so many years only because of her. And now I
                                            am on my way to healing myself and I already feel positive, light and
                                            confident. Payal displayed deep understanding on the subject matter of
                                            weight. She is not only a gifted therapist but also a very genuine, kind and
                                            loving person. Her demeanor and mannerisms put me at ease immediately. I
                                            could easily talk to her and she very smoothly guided me throughout the
                                            session. Have lost 10 kg already. Can't thank her enough!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Tanya Singh</h6>
                                </div>
                            </div>
                        </div>
                        
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-5">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-5">
                                        <p className="text-justify">
                                            I have most of my life suffered from difficulty in saying "NO" to people and
                                            not understanding my own worth hence, always ending up being manipulated
                                            used as a doormat in relationships which in turn made me closed as a person,
                                            staying angry, claustrophobic especially of closed spaces and fear of snakes
                                            was the biggest trigger for me. I signed up for private sessions with Payal
                                            and she walked me through some very intense and emotional sessions and used
                                            RTT, and sent me a recording to listen to for the next 21 days. This was so
                                            helpful and I started to feel like my triggers were becoming “duller” and I
                                            wasn’t as affected by them. Payal made me believe in "I AM ENOUGH” , I have
                                            started to believe in myself as a lovely person who is enough and beautiful
                                            inside out. Payal is very passionate and knowledgeable in her practice and
                                            it reflects in the work she’s done with me, the results are amazing and I
                                            highly recommend working with her.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Varuna Mehta</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-6">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-6">
                                        <p className="text-justify">
                                            Due to stress and tension, my hair started turning grey and I had major hair
                                            fall issues. I was about to go for a hair transplant and I was recommended
                                            to Payal. I thought of giving this a try and I am so delighted in writing
                                            this… Thanks to Payal and RTT I have hair growing on my scalp. The bald
                                            patches are visible no more. And the best part is that the new hair growing
                                            is black. Am so surprised the way this has worked on me. The recording makes
                                            me feel good about myself. In fact I am still listening to my recording
                                            thrice every week. I simply love it.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Tarun Khurana</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-8">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-8">
                                        <p className="text-justify">
                                            I went to Payal for quitting smoking and that too with a very sceptical mind
                                            and she very patiently and politely addressed all my queries. Once I
                                            underwent hypnotherapy, I can’t tell you how awesome I felt. I understood
                                            why, where and how I developed this habit of smoking. I understood myself
                                            better. Thanks to Payal today I have quit smoking completely and I have a
                                            better understanding of my own self, my feelings and my being. RTT is
                                            wonderful and Payal is a brilliant therapist. <br />
                                            Thank you so much.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Avinash Rathore</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-9">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-9">
                                        <p className="text-justify">
                                            A million thanks to Payal. How beautifully she worked on my issue and it has
                                            helped me immensely. I feel like a totally different person now, much more
                                            confident and stable. My understanding of interpersonal relations has
                                            deepened. I have successfully let go of the grief and grievances of my life.
                                            I feel Enough!! So grateful to Payal for being so polite, understanding and
                                            patient. She made the whole process easy. I really recommend her and RTT.
                                            <br />
                                            Thank you

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Mehul Parekh</h6>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-11">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-11">
                                        <p className="text-justify">
                                            For many years, I had been reliant on sleeping pills, and it felt like I
                                            would never break free from that cycle. But thanks to the incredible Healy
                                            and its frequencies, I’ve experienced a transformation that I couldn’t be
                                            happier about. As I started receiving the frequencies daily, soon I felt
                                            relaxed and I noticed that I didn’t wake up that often and I had stopped
                                            taking the extra sleeping pill at midnight. And gradually as days passed by,
                                            I was confident in reducing the dosages of the pills. Now I have a
                                            significant improvement in the quality of sleep. It’s an achievement that ‘m
                                            incredibly proud of, and it’s given me a renewed sense of hope and
                                            motivation at the age of 68. I thank Payal wholeheartedly for recommending
                                            Healy and running programs for me.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Asha Ghelani</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-12">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-12">
                                        <p className="text-justify">
                                            Payal's hypnotherapy sessions transformed my life completely. When the
                                            lockdown hit, my business took a severe hit, causing me anxiety and
                                            deteriorating health. However, after just a single session with Payal, I
                                            experienced a remarkable shift. My nerves calmed down, my health began to
                                            improve, and my self-confidence soared. With Payal's unwavering support, I
                                            not only rebuilt my business but also found a renewed sense of gratitude for
                                            her invaluable assistance during a critical phase of my life.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Bhaumik Sanghvi</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-10">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-10">
                                        <p className="text-justify">
                                            I had been struggling with water retention for quite some time, and nothing
                                            seemed to provide lasting relief. Then, I tried a customized essential oil
                                            blend recommended by Payal, my therapist, and the results were truly
                                            remarkable.
                                            The blend contained natural extracts known for their anti-inflammatory and
                                            diuretic properties. I applied the blend regularly, massaging it gently onto
                                            the bottom of my feet.
                                            Within just a few days, I noticed a significant reduction in water retention
                                            and felt much lighter overall. The discomfort from water retention
                                            diminished, and I was amazed at how effective this natural remedy was
                                            compared to other treatments I had tried. I am incredibly grateful for this
                                            discovery and would highly recommend it to anyone experiencing similar
                                            issues. The power of nature, harnessed through essential oils, has made a
                                            significant difference in my quality of life.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Sonal Mehta </h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-4">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-4">
                                        <p className="text-justify">
                                            My husband had been drinking and smoking for many years. He started putting
                                            on a lot of weight and his health parameters were showing red lines. He
                                            refused to acknowledge that something had to done. He outright refused any
                                            medical treatments. Desperate for a solution, while surfing the net, I came
                                            across InSync Therapies. I connected with Payal and God’s grace found a
                                            breakthrough.
                                            She consulted us and seeing my husband’s reluctance she recommended Healy
                                            frequencies where he didn’t have to do anything apart from providing
                                            feedback. He agreed. She also gave me a combination of oil to diffuse and
                                            another one for application on my husband’s feet at night.
                                            It’s been two months now and am so happy to say that not only has my
                                            husband’s drinking and smoking reduced but he also now is keen on working
                                            out, following a healthy diet and is concerned about his fitness. The shift
                                            in his mindset is just amazing. <br />
                                            Thank you Payal.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Anonymous</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-4">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-4">
                                        <p className="text-justify">
                                            I have been dealing with chronic aches and pains for years, trying
                                            everything from medications to physical therapy with only temporary relief.
                                            When I heard about alternative healing modalities and contacted Payal at
                                            InSync Therapies. After hearing me out she recommended a dual approach of
                                            the Healy frequency and oil blend, I was intrigued by its holistic approach
                                            using Quantum Sensor and Frequency Technology and natural extracts so I
                                            decided to give it a try.
                                            Payal ran programs on Healy according to my requirements. She took regular
                                            feedback and altered the programs accordingly. The Aura scans left me
                                            astonished as it would catch the emotions, I was feeling but not saying out
                                            loud. The targeted frequency programs tailored to my specific pain areas
                                            provided significant relief. I felt more relaxed, with improved mobility and
                                            a decrease in inflammation. Healy's ability to analyse my body's vibrations
                                            and address the root cause of the pain was a game-changer.
                                            While Healy frequencies were bringing changes the pain was kept under
                                            control with the oil blend. The blend worked on various things as well.
                                            Apart from providing relief it made me feel better, lighter and calmer.
                                            The holistic healing approach worked wonders for me. It has truly
                                            transformed my life. The chronic pain that once limited my activities is now
                                            manageable, and I feel more energetic and positive. I am incredibly grateful
                                            for finding Payal and trusting her.
                                            <br />
                                            Thank you so much!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Shivani Mehra</h6>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-5">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-5">
                                        <p className="text-justify">
                                            I have struggled with various health issues including chronic pain, stress,
                                            mental block, fatigue and sleep problems. Traditional treatments provided
                                            only temporary relief, and I was searching for a holistic solution when I
                                            discovered InSync Therapies online.
                                            Consulting Payal changed my life. She heard me patiently and understood
                                            things that I could never explain anyone. Considering different issues, I
                                            was going through, she recommended a holistic approach. We started using
                                            plant extracts, Healy frequencies and Counselling. Healy Frequency
                                            Technology to promote overall well-being by aligning the mind, body, and
                                            soul. Personalised oil blends to tackle emotions and provide symptom relief.
                                            And counselling to give me clarity of thoughts and a sense of direction to
                                            move in. From the first session, I felt a significant improvement in my
                                            health. All three together worked wonderfully. It has truly been a
                                            transformative experience. Chronic pain has significantly reduced, stress
                                            levels are more manageable, thoughts are sorted and I finally enjoy restful
                                            sleep due to which fatigue is no more.
                                            I am incredibly grateful to Payal and the kind of work she does. I highly
                                            recommend InSync Therapies to anyone seeking a holistic approach to their
                                            health challenges.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Pritam Khanna</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-12">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-12">
                                        <p className="text-justify">
                                            Due to stress and tension peaceful sleep evaded me, making me drag myself
                                            the entire next day. Restless nights started taking a toll on me. Not
                                            wanting to start sleeping pills, I decided to seek help from Payal.
                                            Following her suggestion, I decided to give essential oil blend a try.
                                            I started applying the blend as directed each evening before bed. I noticed
                                            that I would calm down after using the blend. This led to a significant
                                            improvement in the quality of my sleep. I fell asleep faster, stayed asleep
                                            longer, and woke up feeling refreshed and rejuvenated. The calming effects
                                            of the plant extracts helped ease my mind and body, allowing me to drift
                                            into a deep, restorative sleep. 
                                            <br />Thank you Payal!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Karan Singh</h6>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-13">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-13">
                                        <p className="text-justify">
                                            I am immensely grateful to Payal for introducing me to the world of
                                            essential oils. This has brought a profound and positive transformation in
                                            my life. Previously, I struggled with acne scars and dark spots on my face,
                                            and despite trying numerous products, I never saw any improvements. However,
                                            by using the blend that Payal gave me for facial application, the results
                                            have been simply astonishing. My complexion is now noticeably clearer and
                                            the once prominent dark spots have significantly diminished. Also, I feel my
                                            skin is healthier than before. I am so happy as I no longer feel the need to
                                            rely on makeup to feel confident.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Meera Mehta</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2"></div>
            </div>
        </div>
        <div className="container after-479">
            <div className="row">
                <div className="col-md-12">
                    <div className="jb-section-title text-center mb-4">
                        <h2 className="text-white">Testimonials</h2>
                        <p className="text-white jb-section-subtitle mx-auto">
                            InSync Therapies has touched many lives, here’s how…
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="owl-carousel owl-theme jb-testimonial-slider">
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-1">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-1">
                                        <p className="text-justify">
                                            I am so thankful to God that I met Payal Bhhaskaran, my therapist and a very
                                            lovely person that she is indeed !! <br />
                                            I was struggling with obesity and severe health issues like thyroid and bad
                                            cholesterol and due to all these things I was suffering from mood swings and
                                            depression. The sessions with her be it hypnotherapy or counselling has
                                            immensely helped me in not only lifting my low spirits but also motivated me
                                            in finding self-love and working on myself constructively. I have found this
                                            new person in me who is happy and proactive and takes all the ups & downs
                                            with a pinch of salt. <br />
                                            I have become much calmer and optimistic after my therapy. I would suggest
                                            anyone who is suffering from depression or any kind of mal-adjustment with
                                            life should definitely go for therapy with Payal!! It will surely help you
                                            guys in handling life as it comes in a sensible and composed way.
                                            Thank you once again for everything.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Apurva Joshi</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-2">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-2">
                                        <p className="text-justify">
                                            I had been suffering from chronic pain from TMJ for years and had seen a lot
                                            of doctors who had put me on a number of medicines which gave me temporary
                                            relief only. I came to know about hypnotherapy and met Payal through one of
                                            the internet platforms. As I was unaware of this branch of healing, she
                                            explained things very calmly and patiently. I became keen on trying out the
                                            therapy and I booked an appointment hoping to have some improvement in the
                                            pain. <br />
                                            She took every detail of my case and aptly told me that I was extremely
                                            stressed and exhausted which was adding to the pain, making it worse.
                                            <br />
                                            I started taking sessions from Payal and the pain started to reduce and I
                                            also started feeling positive about myself. After 6 sessions the pain has
                                            completely disappeared. She also gave me personalised recordings to hear
                                            daily which calmed my mind and filled me with positivity.
                                            <br />
                                            I wonder if I had got this therapy done years earlier, I wouldn’t have
                                            suffered so much. <br />
                                            Regards, <br />
                                            Rishabh

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Rishabh</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-3">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-3">
                                        <p className="text-justify">
                                            I am 24 years old and I have been overweight almost all my life. I just
                                            couldn’t deal with being overweight and the remarks that followed leading to
                                            crash diets followed by binge eating. I completely lacked stability and
                                            balance. Payal as a therapist is truly amazing. I could understand the root
                                            cause of being overweight after so many years only because of her. And now I
                                            am on my way to healing myself and I already feel positive, light and
                                            confident. Payal displayed deep understanding on the subject matter of
                                            weight. She is not only a gifted therapist but also a very genuine, kind and
                                            loving person. Her demeanor and mannerisms put me at ease immediately. I
                                            could easily talk to her and she very smoothly guided me throughout the
                                            session. Have lost 10 kg already. Can't thank her enough!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Tanya Singh</h6>
                                </div>
                            </div>
                        </div>
                        
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-5">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-5">
                                        <p className="text-justify">
                                            I have most of my life suffered from difficulty in saying "NO" to people and
                                            not understanding my own worth hence, always ending up being manipulated
                                            used as a doormat in relationships which in turn made me closed as a person,
                                            staying angry, claustrophobic especially of closed spaces and fear of snakes
                                            was the biggest trigger for me. I signed up for private sessions with Payal
                                            and she walked me through some very intense and emotional sessions and used
                                            RTT, and sent me a recording to listen to for the next 21 days. This was so
                                            helpful and I started to feel like my triggers were becoming “duller” and I
                                            wasn’t as affected by them. Payal made me believe in "I AM ENOUGH” , I have
                                            started to believe in myself as a lovely person who is enough and beautiful
                                            inside out. Payal is very passionate and knowledgeable in her practice and
                                            it reflects in the work she’s done with me, the results are amazing and I
                                            highly recommend working with her.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Varuna Mehta</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-6">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-6">
                                        <p className="text-justify">
                                            Due to stress and tension, my hair started turning grey and I had major hair
                                            fall issues. I was about to go for a hair transplant and I was recommended
                                            to Payal. I thought of giving this a try and I am so delighted in writing
                                            this… Thanks to Payal and RTT I have hair growing on my scalp. The bald
                                            patches are visible no more. And the best part is that the new hair growing
                                            is black. Am so surprised the way this has worked on me. The recording makes
                                            me feel good about myself. In fact I am still listening to my recording
                                            thrice every week. I simply love it.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Tarun Khurana</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-8">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-8">
                                        <p className="text-justify">
                                            I went to Payal for quitting smoking and that too with a very sceptical mind
                                            and she very patiently and politely addressed all my queries. Once I
                                            underwent hypnotherapy, I can’t tell you how awesome I felt. I understood
                                            why, where and how I developed this habit of smoking. I understood myself
                                            better. Thanks to Payal today I have quit smoking completely and I have a
                                            better understanding of my own self, my feelings and my being. RTT is
                                            wonderful and Payal is a brilliant therapist. <br />
                                            Thank you so much.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Avinash Rathore</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-9">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-9">
                                        <p className="text-justify">
                                            A million thanks to Payal. How beautifully she worked on my issue and it has
                                            helped me immensely. I feel like a totally different person now, much more
                                            confident and stable. My understanding of interpersonal relations has
                                            deepened. I have successfully let go of the grief and grievances of my life.
                                            I feel Enough!! So grateful to Payal for being so polite, understanding and
                                            patient. She made the whole process easy. I really recommend her and RTT.
                                            <br />
                                            Thank you
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Mehul Parekh</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-11">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-11">
                                        <p className="text-justify">
                                            For many years, I had been reliant on sleeping pills, and it felt like I
                                            would never break free from that cycle. But thanks to the incredible Healy
                                            and its frequencies, I’ve experienced a transformation that I couldn’t be
                                            happier about. As I started receiving the frequencies daily, soon I felt
                                            relaxed and I noticed that I didn’t wake up that often and I had stopped
                                            taking the extra sleeping pill at midnight. And gradually as days passed by,
                                            I was confident in reducing the dosages of the pills. Now I have a
                                            significant improvement in the quality of sleep. It’s an achievement that ‘m
                                            incredibly proud of, and it’s given me a renewed sense of hope and
                                            motivation at the age of 68. I thank Payal wholeheartedly for recommending
                                            Healy and running programs for me.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Asha Ghelani</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-12">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-12">
                                        <p className="text-justify">
                                            Payal's hypnotherapy sessions transformed my life completely. When the
                                            lockdown hit, my business took a severe hit, causing me anxiety and
                                            deteriorating health. However, after just a single session with Payal, I
                                            experienced a remarkable shift. My nerves calmed down, my health began to
                                            improve, and my self-confidence soared. With Payal's unwavering support, I
                                            not only rebuilt my business but also found a renewed sense of gratitude for
                                            her invaluable assistance during a critical phase of my life.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Bhaumik Sanghvi</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-10">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-10">
                                        <p className="text-justify">
                                            I had been struggling with water retention for quite some time, and nothing
                                            seemed to provide lasting relief. Then, I tried a customized essential oil
                                            blend recommended by Payal, my therapist, and the results were truly
                                            remarkable.
                                            The blend contained natural extracts known for their anti-inflammatory and
                                            diuretic properties. I applied the blend regularly, massaging it gently onto
                                            the bottom of my feet.
                                            Within just a few days, I noticed a significant reduction in water retention
                                            and felt much lighter overall. The discomfort from water retention
                                            diminished, and I was amazed at how effective this natural remedy was
                                            compared to other treatments I had tried. I am incredibly grateful for this
                                            discovery and would highly recommend it to anyone experiencing similar
                                            issues. The power of nature, harnessed through essential oils, has made a
                                            significant difference in my quality of life.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Sonal Mehta </h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-4">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-4">
                                        <p className="text-justify">
                                            My husband had been drinking and smoking for many years. He started putting
                                            on a lot of weight and his health parameters were showing red lines. He
                                            refused to acknowledge that something had to done. He outright refused any
                                            medical treatments. Desperate for a solution, while surfing the net, I came
                                            across InSync Therapies. I connected with Payal and God’s grace found a
                                            breakthrough.
                                            She consulted us and seeing my husband’s reluctance she recommended Healy
                                            frequencies where he didn’t have to do anything apart from providing
                                            feedback. He agreed. She also gave me a combination of oil to diffuse and
                                            another one for application on my husband’s feet at night.
                                            It’s been two months now and am so happy to say that not only has my
                                            husband’s drinking and smoking reduced but he also now is keen on working
                                            out, following a healthy diet and is concerned about his fitness. The shift
                                            in his mindset is just amazing. <br />
                                            Thank you Payal.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Anonymous</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-4">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-4">
                                        <p className="text-justify">
                                            I have been dealing with chronic aches and pains for years, trying
                                            everything from medications to physical therapy with only temporary relief.
                                            When I heard about alternative healing modalities and contacted Payal at
                                            InSync Therapies. After hearing me out she recommended a dual approach of
                                            the Healy frequency and oil blend, I was intrigued by its holistic approach
                                            using Quantum Sensor and Frequency Technology and natural extracts so I
                                            decided to give it a try.
                                            Payal ran programs on Healy according to my requirements. She took regular
                                            feedback and altered the programs accordingly. The Aura scans left me
                                            astonished as it would catch the emotions, I was feeling but not saying out
                                            loud. The targeted frequency programs tailored to my specific pain areas
                                            provided significant relief. I felt more relaxed, with improved mobility and
                                            a decrease in inflammation. Healy's ability to analyse my body's vibrations
                                            and address the root cause of the pain was a game-changer.
                                            While Healy frequencies were bringing changes the pain was kept under
                                            control with the oil blend. The blend worked on various things as well.
                                            Apart from providing relief it made me feel better, lighter and calmer.
                                            The holistic healing approach worked wonders for me. It has truly
                                            transformed my life. The chronic pain that once limited my activities is now
                                            manageable, and I feel more energetic and positive. I am incredibly grateful
                                            for finding Payal and trusting her.
                                            <br />
                                            Thank you so much!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Shivani Mehra</h6>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-5">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-5">
                                        <p className="text-justify">
                                            I have struggled with various health issues including chronic pain, stress,
                                            mental block, fatigue and sleep problems. Traditional treatments provided
                                            only temporary relief, and I was searching for a holistic solution when I
                                            discovered InSync Therapies online.
                                            Consulting Payal changed my life. She heard me patiently and understood
                                            things that I could never explain anyone. Considering different issues, I
                                            was going through, she recommended a holistic approach. We started using
                                            plant extracts, Healy frequencies and Counselling. Healy Frequency
                                            Technology to promote overall well-being by aligning the mind, body, and
                                            soul. Personalised oil blends to tackle emotions and provide symptom relief.
                                            And counselling to give me clarity of thoughts and a sense of direction to
                                            move in. From the first session, I felt a significant improvement in my
                                            health. All three together worked wonderfully. It has truly been a
                                            transformative experience. Chronic pain has significantly reduced, stress
                                            levels are more manageable, thoughts are sorted and I finally enjoy restful
                                            sleep due to which fatigue is no more.
                                            I am incredibly grateful to Payal and the kind of work she does. I highly
                                            recommend InSync Therapies to anyone seeking a holistic approach to their
                                            health challenges.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Pritam Khanna</h6>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-12">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-12">
                                        <p className="text-justify">
                                            Due to stress and tension peaceful sleep evaded me, making me drag myself
                                            the entire next day. Restless nights started taking a toll on me. Not
                                            wanting to start sleeping pills, I decided to seek help from Payal.
                                            Following her suggestion, I decided to give essential oil blend a try.
                                            I started applying the blend as directed each evening before bed. I noticed
                                            that I would calm down after using the blend. This led to a significant
                                            improvement in the quality of my sleep. I fell asleep faster, stayed asleep
                                            longer, and woke up feeling refreshed and rejuvenated. The calming effects
                                            of the plant extracts helped ease my mind and body, allowing me to drift
                                            into a deep, restorative sleep. 
                                            <br />Thank you Payal!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Karan Singh</h6>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="jb-testimonial-card shadow-md" id="jb-testimonial-card-13">
                                <div className="row">
                                    <div className="col tes-para" id="tes-para-13">
                                        <p className="text-justify">
                                            I am immensely grateful to Payal for introducing me to the world of
                                            essential oils. This has brought a profound and positive transformation in
                                            my life. Previously, I struggled with acne scars and dark spots on my face,
                                            and despite trying numerous products, I never saw any improvements. However,
                                            by using the blend that Payal gave me for facial application, the results
                                            have been simply astonishing. My complexion is now noticeably clearer and
                                            the once prominent dark spots have significantly diminished. Also, I feel my
                                            skin is healthier than before. I am so happy as I no longer feel the need to
                                            rely on makeup to feel confident.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="jb-user-txt">
                                <div className="jb-user-info">
                                    <h6 className="heading text-white">Meera Mehta</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    )
}