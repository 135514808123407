import ScrollAnimation from 'react-animate-on-scroll';

export const CertificationsComponent: React.FC = () => {
    return (
        <section className="section" id="Certifications">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="jb-section-title text-center mb-4">
                        <h2>Certifications</h2>
                    </div>
                </div>
            </div>
            <ScrollAnimation animateIn="slideInRightJb" animateOnce duration={1} className="row">
                <div className="col-md-4">
                    <div className="p-3 align-self-center">
                        <div className="row story-img-gallery jb-b-color">
                            <div className="col gallery gallery-1">
                                <div className="gallery-img">
                                    <div className="jb-cert-border">
                                        <img className="img-fluid" src="assets/cert-chyp.png" alt="Clinical-Hypno-therapist-Certification"
                                            title="Clinical Hypno-therapist Certification Payal Bhaskaran Insync Therapies" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="p-3 align-self-center">
                        <div className="row story-img-gallery jb-b-color">
                            <div className="col gallery gallery-1">
                                <div className="gallery-img">
                                    <div className="jb-cert-border">
                                        <img className="img-fluid" src="assets/cert-rttp.png" alt="Rapid-Transformational-Therapy-Practitioner-Certification"
                                            title="Rapid Transformational Therapy Practitioner Certification Payal Bhaskaran Insync Therapies" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="p-3 align-self-center">
                        <div className="row story-img-gallery jb-b-color">
                            <div className="col gallery gallery-1">
                                <div className="gallery-img">
                                    <div className="jb-cert-border">
                                        <img className="img-fluid" src="assets/cert-cbt.png" alt="Cognitive-Behavioural-Therapy(CBT)-Certification"
                                            title="Cognitive Behavioural Therapy (CBT) Certification Payal Bhaskaran Insync Therapies" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="p-3 align-self-center">
                        <div className="row story-img-gallery jb-b-color">
                            <div className="col gallery gallery-1">
                                <div className="gallery-img">
                                    <div className="jb-cert-border">
                                        <img className="img-fluid" src="assets/cert-access-bars.png" alt="Access-Bars-Certification"
                                            title="Access Bars Certification Payal Bhaskaran Insync Therapies" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="p-3 align-self-center">
                        <div className="row story-img-gallery jb-b-color">
                            <div className="col gallery gallery-1">
                                <div className="gallery-img">
                                    <div className="jb-cert-border">
                                        <img className="img-fluid" src="assets/cert-eos-spec.png" alt="Essential-Oil-Specialist-Certification"
                                            title="Essential Oil Specialist Certification Payal Bhaskaran Insync Therapies" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="p-3 align-self-center">
                        <div className="row story-img-gallery jb-b-color">
                            <div className="col gallery gallery-1">
                                <div className="gallery-img">
                                    <div className="jb-cert-border">
                                        <img className="img-fluid" src="assets/cert-eos-safety.png" alt="Essential-Oil-Safety-Certification"
                                            title="Essential Oil Safety Certification Payal Bhaskaran Insync Therapies" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
        </div>
    </section>
    )
}